import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const ContentBlock01_impressum = ({ content: { collection } }) => (
  <Container>
    <Flex
      sx={{
        flexDirection: [`column`, null, null, `row`]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, null],
          'mr': [null, null, null, 5],
          position: `relative`,
          textAlign: ['center', 'left']
        }}
      >
        {collection?.[0] && (
            <Box sx={{ textAlign: ['left', 'left'] }}>
              <ContentText content={collection[0]?.text} sx={{display: [`none`, null, `block`]}} ml='0' />
              <ContentText content={collection[1]?.text} sx={{display: [`block`, null, `none`]}} ml='0' />
            </Box>
        )}
      </Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(ContentBlock01_impressum)
